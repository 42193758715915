import { useEffect, useState } from 'react';

type TitleGenerator<T> = (obj: T) => string;

export const useTitle = <T,>(
    obj: T | undefined,
    generateTitle: TitleGenerator<T>
): string => {
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        if (obj) {
            const newTitle = generateTitle(obj);
            setTitle(newTitle);
        } else {
            setTitle('');
        }
    }, [obj, generateTitle]);

    return title;
};
