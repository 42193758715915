import React, { FC } from 'react';
import { Col, Form, Row, Spin } from '@pankod/refine-antd';
import dayjs from 'dayjs';
import { strToDate } from '../../../../utils/accessory';
import { DataTable } from '../../../data-table';
import { FileUploader, Success } from '../../../file-uploader';
import { useTranslation } from 'react-i18next';
import { toUpCase } from '../../../../utils/misc';

interface ApplicationInfoProps {
    isLoading: boolean;
    formProps: any;
    handleFinish: (values: any) => Promise<void>;
    handleSuccess: (id: Success) => void;
    fileLocked: boolean | number;
    message: (any | undefined)[];
    changes: any[];
}

export const ApplicationInfo: FC<ApplicationInfoProps> = ({
    formProps,
    isLoading,
    handleFinish,
    fileLocked,
    message,
    changes,
    handleSuccess,
}) => {
    const { t } = useTranslation();
    return (
        <Spin spinning={isLoading}>
            <Form {...formProps} layout="vertical" onFinish={handleFinish}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.name')}
                            name="name"
                        >
                            <div>{formProps?.initialValues?.name}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.pharmaceutical_form')}
                            name="pharmaceutical_form"
                        >
                            <div>
                                {
                                    formProps?.initialValues?.medication
                                        ?.pharmaceuticalForm
                                }
                            </div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.created_at')}
                            name="created_at"
                            getValueProps={value => ({
                                value: value ? dayjs(strToDate(value)) : '',
                            })}
                        >
                            <div>{formProps?.initialValues?.created_at}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.updated_at')}
                            name="updated_at"
                            getValueProps={value => ({
                                value: value ? dayjs(strToDate(value)) : '',
                            })}
                        >
                            <div>{formProps?.initialValues?.updated_at}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'applications.fields.registration_certificate_number'
                            )}
                            name="registration_certificate_number"
                        >
                            <div>
                                {
                                    formProps?.initialValues
                                        ?.registration_certificate_number
                                }
                            </div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'applications.fields.registration_certificate_date'
                            )}
                            name="registration_certificate_date"
                        >
                            <div>
                                {
                                    formProps?.initialValues
                                        ?.registration_certificate_date
                                }
                            </div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.procedure')}
                            name={['status', 'id']}
                        >
                            <div>{formProps?.initialValues?.type?.name}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.status')}
                            name={['status', 'id']}
                        >
                            <div>{formProps?.initialValues?.status?.name}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.applicant')}
                            name={['applicant', 'id']}
                        >
                            <div>
                                {formProps?.initialValues?.applicant_name ??
                                    formProps?.initialValues
                                        ?.representative_name ??
                                    formProps?.initialValues?.applicant
                                        ?.first_name}
                            </div>
                        </Form.Item>
                    </Col>

                    {message.length && (
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={t('applications.fields.expertise')}
                            >
                                <div>
                                    {/*// @ts-ignore*/}
                                    {message &&
                                        toUpCase(
                                            message?.[0]?.expertise_status
                                        )}
                                </div>
                            </Form.Item>
                        </Col>
                    )}

                    {message.length && (
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={t('applications.fields.state_tax')}
                            >
                                <div>
                                    {/*// @ts-ignore */}
                                    {message &&
                                        toUpCase(
                                            message?.[0]
                                                ?.state_collection_status
                                        )}
                                </div>
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.uuid')}
                            name={'guid'}
                        >
                            <div>{formProps?.initialValues?.guid}</div>
                        </Form.Item>
                    </Col>

                    {changes?.length ? (
                        <Col xs={24} md={24}>
                            <DataTable
                                data={changes}
                                group="applications.titles"
                            />
                        </Col>
                    ) : null}

                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.file')}
                            name="file_id"
                            rules={[
                                {
                                    required: true,
                                    message: t('required_field'),
                                },
                            ]}
                        >
                            <FileUploader
                                onSuccess={handleSuccess}
                                disabled={!!fileLocked}
                                message={message}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};
