import { useMemo } from 'react';
import i18n from 'i18next';

const existTranslate = (key: string) => i18n.exists(`labels.${key}`);

export const usePageName = (pathname: string | undefined) => {
    const pageName = useMemo(() => {
        const segments = pathname!.split('/').filter(Boolean);
        const translatedParts = segments.map(segment => {
            if (existTranslate(segment)) {
                return i18n.t(`labels.${segment}`);
            }

            return segment;
        });

        return translatedParts.join(' - ');
    }, [pathname]);

    return { pageName };
};
