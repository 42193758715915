import React, { createContext, FC, ReactNode } from 'react';
import {
    INotificationContext,
    INotificationOptions,
} from '../interfaces/notification';
import { v4 as uuidv4 } from 'uuid';
import { notificationProvider } from '../provider/notificationProvider';

const defaultNotificationContext: INotificationContext = {
    open: () => {},
    close: () => {},
    msgSuccess: () => {},
    msgError: () => {},
    msgProgress: () => {},
};

export const NotificationContext = createContext<INotificationContext>(
    defaultNotificationContext
);

interface NotificationContextProviderProps {
    children: ReactNode;
}

export const NotificationContextProvider: FC<
    NotificationContextProviderProps
> = ({ children }) => {
    const open = ({
        key,
        message,
        description,
        type = 'progress', // Змінено з 'info' на 'progress'
    }: INotificationOptions) => {
        notificationProvider.open({ key, message, description, type });
    };

    const close = (key: string) => {
        notificationProvider.close(key);
    };

    const msgSuccess = (message: string, description?: string) => {
        open({ key: uuidv4(), message, description, type: 'success' });
    };

    const msgError = (message: string, description?: string) => {
        open({ key: uuidv4(), message, description, type: 'error' });
    };

    const msgProgress = (message: string, description?: string) => {
        open({ key: uuidv4(), message, description, type: 'progress' });
    };

    return (
        <NotificationContext.Provider
            value={{ open, close, msgSuccess, msgError, msgProgress }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
