import { useSelect } from '@pankod/refine-antd';
import { ICategory } from '../../interfaces';

export const useDictionarySelectProps = () => {
    const { selectProps: applicationStatusProps } = useSelect<ICategory>({
        resource: 'dictionary/application-statuses',
        optionLabel: 'name',
        optionValue: 'id',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: applicationTypeProps } = useSelect<ICategory>({
        resource: 'dictionary/application-types',
        optionLabel: 'name',
        optionValue: 'id',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    return {
        applicationStatusProps,
        applicationTypeProps,
    };
};
