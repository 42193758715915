import React from 'react';
import { ISpecificationItem } from '../../../pages/specs/show/show';

export const tabulate = (array: any, tr: any) => {
    return (
        <table>
            {array.map((row: ISpecificationItem, rKey: number) => {
                return (
                    <tr key={rKey}>
                        {Object.keys(row).map((cell: string, index: number) => {
                            // @ts-ignore
                            let value = row?.[cell];
                            let child = value?.props?.children;
                            let href = value?.props?.href;
                            let trs =
                                child && href ? (
                                    <a
                                        href={href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {tr(child)}
                                    </a>
                                ) : (
                                    tr(value)
                                );

                            return (
                                <td key={index}>
                                    <p>{trs}</p>
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </table>
    );
};
