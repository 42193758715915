const roleAccess = {
    Expert: [
        'applications',
        'preliminary-applications',
        'applications-external',
        'form',
        'history',
        'clinical-trial',
        'menu-forms',
        'activity',
        'dictionaries',
    ],
    // Заявник
    ApplicantUser: [
        'applications',
        'preliminary-applications',
        'applications-external',
        'form',
        'history',
        'clinical-trial',
        'menu-forms',
    ],
    // Заявник те ж що і "ApplicantUser"
    ApplicantRoot: [
        'applications',
        'preliminary-applications',
        'applications-external',
        'form',
        'history',
        'clinical-trial',
        'menu-forms',
    ],
    PreliminaryUser: ['preliminary-applications'],
};

export default roleAccess;
