import React, { FC } from 'react';
import { AntdLayout, Col, Row } from '@pankod/refine-antd';
import cn from 'classnames';
import styles from './PublicHeader.module.scss';
import { Menu } from '../menu';
import { useRouterContext } from '@pankod/refine-core';
import Container from '../container';
import LangSwitching from '../../lang-switching';
import { Trans, useTranslation } from 'react-i18next';

export const Header: FC = () => {
    const { Link } = useRouterContext();
    const { t } = useTranslation();

    return (
        <AntdLayout.Header className={styles.header}>
            <Container>
                <Row style={{ width: '100%' }}>
                    <Col
                        className={cn(
                            styles.logoColumn,
                            'ant-col ant-col-xs-24 ant-col-lg-3'
                        )}
                    >
                        <div className={styles.logoContainer}>
                            <Link to="/">
                                <img
                                    src="/images/logo.svg"
                                    alt={t(
                                        'pages.login.state_enterprise_state_expert_center_of_the_ministry_of_health_of_ukraine_description'
                                    )}
                                    width={90}
                                    height={90}
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col
                        className={cn(
                            styles.textColumn,
                            'ant-col ant-col-xs-24 ant-col-lg-22'
                        )}
                    >
                        <Row>
                            <Col xs={24}>
                                <div className={styles.topText}>
                                    <Trans i18nKey="pages.login.state_enterprise_state_expert_center_of_the_ministry_of_health_of_ukraine">
                                        State Enterprise State <br />
                                        <span /> Ministry of Health of Ukraine
                                    </Trans>
                                </div>
                                <div className={styles.horLine} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={12} xl={12}>
                                <Menu />
                            </Col>
                            <Col xs={24} lg={12} xl={12}>
                                <div className={styles.rightSidePanel}>
                                    <LangSwitching />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </AntdLayout.Header>
    );
};
