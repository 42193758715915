import { useContext } from 'react';
import { NotificationContext } from '../context/NotificationContext';
import { INotificationContext } from '../interfaces/notification';

export const useNotification = (): INotificationContext => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error(
            'useNotification must be used within a NotificationContextProvider'
        );
    }
    return context;
};
