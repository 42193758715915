import { UploadFile } from 'antd';
import { FormInstance } from 'antd/es/form';
import { NamePath } from 'antd/es/form/interface';

interface ValidateAndAppendFilesParams {
    fileList: UploadFile[];
    formData: FormData;
    form: FormInstance;
    resetStates: (fields?: NamePath[], formInstance?: FormInstance) => void;
    message: {
        error: (msg: string) => void;
    };
    sleep: (ms: number) => Promise<void>;
    LOADER_DELAY_IN_MS: number;
    allowedTypes?: string[];
    maxSizeMB?: number;
}

export async function validateAndAppendFiles({
    fileList,
    formData,
    form,
    resetStates,
    message,
    sleep,
    LOADER_DELAY_IN_MS,
    allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'],
    maxSizeMB = 10,
}: ValidateAndAppendFilesParams): Promise<void> {
    for (const fileItem of fileList) {
        if (fileItem.originFileObj) {
            const { type, size, name } = fileItem.originFileObj;

            if (!allowedTypes.includes(type)) {
                await sleep(LOADER_DELAY_IN_MS);
                resetStates(['files'], form);
                message.error(`${name} має неправильний тип файлу.`);
                return;
            }

            if (size / 1024 / 1024 >= maxSizeMB) {
                await sleep(LOADER_DELAY_IN_MS);
                resetStates(['files'], form);
                message.error(`${name} перевищує ${maxSizeMB}MB.`);
                return;
            }

            formData.append('files[]', fileItem.originFileObj as File);
        }
    }
}
