import { IFormValues } from '../interfaces/preliminary-application';

export const mapStoreToFormValues = (store: IFormValues): IFormValues => {
    return {
        uuid: store.uuid,
        reg_num: store.reg_num,
        reg_date: store.reg_date,
        is_electronic_form: store.is_electronic_form,
        is_mibp: store.is_mibp,
        medication_name: store.medication_name,
        release_form: store.release_form,
        certificate: store.certificate,
        applicant_id: store.applicant_id,
        procedure_id: store.procedure_id,
        state_id: store.state_id,
        organization_id: store.organization_id,
        created_by_id: store.created_by_id,
        modified_by_id: store.modified_by_id,
        created: store.created,
        modified: store.modified,
        procedure_desc: store.procedure_desc,
        reject_reason: store.reject_reason,
        notification_email: store.notification_email,
        ct_code: store.ct_code,
        ct_version: store.ct_version,
        ct_applicant_id: store.ct_applicant_id,
        sponsor_id: store.sponsor_id,
        amendment_id: store.amendment_id,
        notes: store.notes,
        application_notes: store.application_notes,
        is_ectd: store.is_ectd,
        inn: store.inn,
        international_name_id: store.international_name_id,
        files_count: store.files_count,
        state: store.state,
        files: store.files,
        manufacturers: store.manufacturers,
        manufacturer_id: store.manufacturer_id,
        ...store,
    };
};
