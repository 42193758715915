import React, { FC } from 'react';
import { LayoutProps, useTranslate } from '@pankod/refine-core';
import { AntdLayout, Grid } from '@pankod/refine-antd';
import { Header } from '../../public-header';
import { Footer } from '../../footer';
import { OffLayoutArea } from '../../offLayoutArea';
import Container from '../../container';
import { getFullYear } from '../../../../utils/date';
import styles from '../Layout.module.scss';

export const Layout: FC<LayoutProps> = ({ children }) => {
    const year: number = getFullYear();
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    return (
        <AntdLayout className={styles.pageWrapper}>
            <AntdLayout className={styles.innerPageWrapper}>
                <Header />
                <AntdLayout.Content>
                    <div
                        style={{
                            padding: breakpoint.sm ? 24 : 12,
                            minHeight: 360,
                        }}
                    >
                        <Container
                            style={{
                                width: '100%',
                            }}
                        >
                            {children}
                        </Container>
                    </div>
                    <OffLayoutArea />
                </AntdLayout.Content>
                <Footer>
                    © {year} {t('all_rights_reserved')}
                </Footer>
            </AntdLayout>
        </AntdLayout>
    );
};
