import {
    NotificationProvider as RefineNotificationProvider,
    OpenNotificationParams,
} from '@pankod/refine-core';
import { notification } from 'antd';

export const notificationProvider: RefineNotificationProvider = {
    open: ({
        key,
        message,
        description,
        type = 'progress',
    }: OpenNotificationParams) => {
        const antdType = type === 'progress' ? 'info' : type;

        notification.open({
            key,
            message: message as string, // Переконайтесь, що message є string
            description: description as string | undefined, // Переконайтесь, що description є string або undefined
            type: antdType as 'success' | 'error' | 'info' | 'warning', // Кастинг до допустимих типів
        });
    },
    close: (key: string) => {
        notification.close(key);
    },
};
