import { FC } from 'react';
import { RecordStatus } from '../applications';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import styles from './IconStatus.module.scss';

interface IconStatusProps {
    type:
        | 'validation_successful'
        | 'awaiting_validation'
        | 'validation_error'
        | 'validation_warning'
        | 'validation_notice';
}

export const IconStatus: FC<IconStatusProps> = ({ type }) => {
    switch (type) {
        case RecordStatus['ValidationSuccessful']:
            return (
                <div className={styles.iconSuccess}>
                    <CheckCircleOutlined />
                </div>
            );
        case RecordStatus['ValidationWarning']:
            return (
                <div className={styles.iconWarning}>
                    <WarningOutlined />
                </div>
            );
        case RecordStatus['ValidationError']:
            return (
                <div className={styles.iconError}>
                    <CloseCircleOutlined />
                </div>
            );
        case RecordStatus['ValidationNotice']:
            return (
                <div className={styles.iconNotice}>
                    <InfoCircleOutlined />
                </div>
            );
        default:
            return <></>;
    }
};
