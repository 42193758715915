import { FC } from 'react';
import {
    Button,
    Col,
    Form,
    FormProps,
    Input,
    Row,
    Select,
} from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';

import styles from './Filter.module.scss';
import { useDictionarySelectProps } from '../../../hooks/applications/useDictionarySelectProps';

export const Filter: FC<{ formProps: FormProps }> = ({ formProps }) => {
    const t = useTranslate();
    const { applicationTypeProps, applicationStatusProps } =
        useDictionarySelectProps();

    return (
        <Form layout="vertical" {...formProps} className={styles.filterWrapper}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('applications.fields.query')}
                        name="query"
                    >
                        <Input
                            allowClear
                            placeholder={t('applications.fields.query')}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('applications.fields.procedure')}
                        name="type_id"
                    >
                        <Select
                            {...applicationTypeProps}
                            allowClear
                            placeholder={t('applications.fields.type')}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('applications.fields.ectd_dossier_status')}
                        name="status_id"
                    >
                        <Select
                            {...applicationStatusProps}
                            allowClear
                            placeholder={t(
                                'applications.fields.ectd_dossier_status'
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t('applications.buttons.filter')}
                </Button>
            </Form.Item>
        </Form>
    );
};
