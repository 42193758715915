import { FormInstance } from 'antd';
import { errorManipulation } from './error-manipulation';

interface ErrorCollection {
    [key: string]: string[];
}

interface HandleFormErrorParams {
    e: any;
    form: FormInstance;
    setIsFormProcessing: (val: boolean) => void;
    setDisSubmit: (val: boolean) => void;
    msgError: (msg: string) => void;
    t: (key: string) => string; // або як у тебе типізована функція перекладу
}

export function handleFormError({
    e,
    form,
    setIsFormProcessing,
    setDisSubmit,
    msgError,
    t,
}: HandleFormErrorParams) {
    console.error(e);

    // Спробуємо витягти колекцію помилок
    const errCollection = e?.response?.data?.errors as
        | ErrorCollection
        | undefined;

    if (errCollection) {
        // Перетворюємо об'єкт на масив "errFields"
        const errFields = Object.entries(errCollection).map(([key, msgs]) => ({
            name: key.split('.').map(i => (Number.isInteger(+i) ? +i : i)),
            errors: errorManipulation(msgs),
        }));

        form.setFields(errFields);
    }

    // Скидаємо спінери й не даємо кнопці бути disabled
    setIsFormProcessing(false);
    setDisSubmit(false);

    // Показуємо повідомлення про помилку
    msgError(t('notifications.entry_error'));
}
