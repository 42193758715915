import React, { FC } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useTranslate } from '@pankod/refine-core';
import styles from './MultiFileUploader.module.scss';

interface MultiFileUploaderProps {
    fileList: UploadFile[];
    onChange: (fileList: UploadFile[]) => void;
    maxCount?: number;
    accept?: string;
    listType?: 'text' | 'picture' | 'picture-card';
    multiple?: boolean;
}

const MultiFileUploader: FC<MultiFileUploaderProps> = ({
    fileList,
    onChange,
    maxCount = 3,
    accept = '.jpg,.png,.pdf',
    listType = 'picture',
    multiple = true,
}) => {
    const t = useTranslate();

    const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        const { fileList: newFileList } = info;
        onChange(newFileList);
    };

    const handleRemove = (file: UploadFile<any>) => {
        message.info(`${file.name} ${t('notifications.deleted')}`);
    };

    const beforeUpload = (file: File): boolean => {
        return false;
    };

    return (
        <Upload
            fileList={fileList}
            onChange={handleChange}
            onRemove={handleRemove}
            beforeUpload={beforeUpload}
            listType={listType}
            maxCount={maxCount}
            multiple={multiple}
            accept={accept}
            className={styles.uploader}
        >
            <Button type="primary" icon={<UploadOutlined />}>
                {t('select_a_file')}
            </Button>
        </Upload>
    );
};

export default MultiFileUploader;
