import React, { FC, useState } from 'react';
import { Form, Button, Space, Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, Row, useSelect } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ICategory } from '../../../../../interfaces';

interface ChangesFormProps {
    fieldKey: number;
    [key: string]: any;
}

export const ChangesForm: FC<ChangesFormProps> = ({ fieldKey }) => {
    const t = useTranslate();
    const [checkbox, setCheckbox] = useState<any>([]);

    const { selectProps: changesProps } = useSelect<ICategory>({
        resource: 'dictionary/changes',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const handleSelect = (event: CheckboxChangeEvent) => {
        let target = event.target;
        let id = target.id!.split('_').at(-2);

        if (target.checked) {
            setCheckbox(id);
        } else {
            setCheckbox(undefined);
        }
    };

    return (
        <>
            <Form.List name={[fieldKey, 'changes']}>
                {(changes, { add, remove }, { errors, warnings }) => {
                    return (
                        <>
                            {changes.map((change, index, array) => {
                                return (
                                    <Space
                                        key={change.key}
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            marginBottom: 8,
                                            width: '100%',
                                        }}
                                        align="baseline"
                                    >
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={24}>
                                                <Form.Item
                                                    {...change}
                                                    name={[
                                                        change.name,
                                                        'changeId',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        change.fieldKey,
                                                        'changeId',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.change'
                                                    )}
                                                >
                                                    <Select {...changesProps} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={10}>
                                                <Form.Item
                                                    {...change}
                                                    name={[
                                                        change.name,
                                                        'description',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        change.fieldKey,
                                                        'description',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.description'
                                                    )}
                                                >
                                                    <Input.TextArea />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={2}>
                                                <Form.Item
                                                    {...change}
                                                    name={[
                                                        change.name,
                                                        'isMain',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        change.fieldKey,
                                                        'isMain',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.is_main'
                                                    )}
                                                    valuePropName="checked"
                                                    hidden={
                                                        checkbox?.length
                                                            ? checkbox !==
                                                              change.name.toString()
                                                            : false
                                                    }
                                                >
                                                    <Checkbox
                                                        onChange={handleSelect}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={4}>
                                                <MinusCircleOutlined
                                                    style={{
                                                        top: 35,
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => {
                                                        remove(change.name);
                                                        if (
                                                            checkbox ===
                                                            change.name.toString()
                                                        ) {
                                                            setCheckbox(
                                                                undefined
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Space>
                                );
                            })}
                            <Form.Item noStyle>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                    }}
                                >
                                    <PlusOutlined />{' '}
                                    {t('applications.fields.add_changes')}
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    );
                }}
            </Form.List>
        </>
    );
};
