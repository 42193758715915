import i18n from '../i18n';

export const errorManipulation = (error: string[] | undefined): string[] => {
    if (!error) return [];

    return error.map((errMsg: string) => {
        const [article, message, ...rest] = errMsg.split(' ');
        const text = rest.join(' ');
        const processedMessage = message.split('.').at(-1) || message;
        return i18n.t(`${article} ${processedMessage} ${text}`);
    });
};
