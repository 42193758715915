import { FC } from 'react';
import { Card, Col, FormProps, Row, Typography } from '@pankod/refine-antd';
import { Filter } from '../filter';
import { useTranslation } from 'react-i18next';

interface IFilterPanelProps {
    searchForm: FormProps;
}

export const FilterPanel: FC<IFilterPanelProps> = ({ searchForm }) => {
    const { t } = useTranslation();
    return (
        <Row gutter={[16, 32]}>
            <Col lg={24} xs={24}>
                <Card title={t('activity.titles.filters')}>
                    <Filter searchForm={searchForm} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <Typography.Text>{t('activity.activity')}</Typography.Text>
            </Col>
        </Row>
    );
};
