import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    Col,
    Row,
    Button,
    useSelect,
    Select,
    ShowButton,
} from '@pankod/refine-antd';
import { IRecord, IRecordFilterVariables, IApplication } from 'interfaces';
import { FC, useMemo, useState } from 'react';
import { api } from '../../../utils/api';
import { useNotification } from '../../../hooks/useNotification';
import styles from 'components/pharmasol/Pharmasol.module.scss';

interface IDictionaryKey {
    dictionary_name: string;
}

export const PharmasolList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const [dictKey, setDictKey] = useState<IDictionaryKey | undefined>(
        undefined
    );
    const { msgSuccess } = useNotification();

    const { tableProps, sorter } = useTable<
        IApplication,
        HttpError,
        IRecordFilterVariables
    >({
        resource: 'pharmasol/dictionaries/sync',
    });

    const { selectProps: dictionarySyncProps } = useSelect<IRecord>({
        resource: 'pharmasol/dictionaries/sync',
        defaultValue: [],
        optionValue: 'dictionary_name',
        optionLabel: 'dictionary_name',
    });

    const transDictionarySyncProps = useMemo(() => {
        return {
            ...dictionarySyncProps,
            options:
                dictionarySyncProps?.options?.map((option: any) => {
                    return {
                        value: option.value,
                        label: t(`labels.${option.label}`),
                    };
                }) ?? [],
        };
    }, [dictionarySyncProps, t]);

    const handleSync = async (filters: any) => {
        try {
            let { status } = await api.post(
                `/api/pharmasol/dictionaries/sync`,
                null,
                {
                    params: {
                        ...filters,
                    },
                }
            );

            if (status === 200) {
                msgSuccess(t('pharmasol.message.synced'));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleChange = (value: any): void => {
        setDictKey({
            dictionary_name: value,
        });
    };

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Row gutter={[16, 16]}>
                    <Col flex="1 0 15%">
                        <Select
                            {...transDictionarySyncProps}
                            onChange={handleChange}
                            placeholder={t('select_dictionary')}
                            allowClear
                            className={styles.dictionarySelect}
                            defaultActiveFirstOption={false}
                        />
                    </Col>
                    <Col flex="0 0 85%">
                        <Button
                            onClick={() => handleSync(dictKey)}
                            type="primary"
                        >
                            {t('pharmasol.buttons.sync')}
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col lg={24} xs={24}>
                <List title={t('pharmasol.dictionaries')}>
                    <Table
                        {...tableProps}
                        rowKey="id"
                        scroll={{ x: 500 }}
                        bordered
                    >
                        <Table.Column
                            dataIndex="dictionary_name"
                            key="dictionary_name"
                            title={t('pharmasol.fields.code')}
                            render={value => <TextField value={value} />}
                            defaultSortOrder={getDefaultSortOrder(
                                'dictionary_name',
                                sorter
                            )}
                            sorter
                        />
                        <Table.Column
                            dataIndex="dictionary_name"
                            key="dictionary_name"
                            title={t('pharmasol.fields.name')}
                            render={value => t(`pharmasol.fields.${value}`)}
                        />
                        <Table.Column
                            dataIndex="lines"
                            key="lines"
                            title={t('pharmasol.fields.lines')}
                            render={value => <TextField value={value} />}
                            defaultSortOrder={getDefaultSortOrder(
                                'lines',
                                sorter
                            )}
                            sorter
                        />
                        <Table.Column
                            dataIndex="last_updates"
                            key="last_updates"
                            title={t('pharmasol.fields.last_updates')}
                            defaultSortOrder={getDefaultSortOrder(
                                'last_updates',
                                sorter
                            )}
                            sorter
                        />
                        <Table.Column<IRecord>
                            title={t('table.actions')}
                            dataIndex="actions"
                            render={(_, record: any) => {
                                let dictionary = record.dictionary_name;

                                return (
                                    <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={dictionary}
                                    />
                                );
                            }}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
