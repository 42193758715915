import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';

const AuthApplicationAdd = React.lazy(
    () => import('../components/layout/custom/auth-application-add')
);

const AuthForm = React.lazy(
    () => import('../components/layout/custom/auth-form')
);

const AuthHistory = React.lazy(
    () => import('../components/layout/custom/auth-history')
);

const AuthClinicalTrial = React.lazy(
    () => import('../components/layout/custom/auth-clinical-trial')
);

const AuthAppExternal = React.lazy(
    () => import('../components/layout/custom/auth-applications-external')
);

const AuthMenuForms = React.lazy(
    () => import('../components/layout/custom/auth-menu-forms')
);

const SpecShow = React.lazy(() => import('../pages/specs/show/show'));
const SpecList = React.lazy(() => import('../pages/specs/list/list'));

const route = {
    ...routerProvider,
    routes: [
        {
            element: <AuthApplicationAdd />,
            path: '/applications/add',
            layout: true,
        },
        {
            element: <AuthForm />,
            path: '/form',
            layout: true,
        },
        {
            element: <AuthHistory />,
            path: '/history',
            layout: true,
        },
        {
            element: <AuthClinicalTrial />,
            path: '/clinical-trial',
            layout: true,
        },
        {
            element: <AuthAppExternal />,
            path: '/applications-external',
            layout: true,
        },
        // {
        //     element: <AuthAppExternal />,
        //     path: '/applications_external',
        //     layout: true,
        // },
        {
            element: <AuthMenuForms />,
            path: '/menu-forms',
            layout: true,
        },
        {
            element: <SpecShow />,
            path: '/specs/:query',
            layout: true,
        },
        {
            element: <SpecList />,
            path: '/specs',
            layout: true,
        },
        // {
        //     element: <AuthenticatedApplicationsFlow />,
        //     path: '/applications/flow',
        //     layout: true,
        // },
    ],
};

export default route;
