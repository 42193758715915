import i18n from 'i18next';

const buildSubmissionRow = (
    item: any,
    keys: any[],
    divider: string = ' '
): string => {
    let str: string = '';

    if (keys.length) {
        keys.forEach((value, index) => {
            let needDiv = true;

            if (keys[keys.length - 1] === keys[index]) {
                needDiv = false;
            }

            let currValue = item?.[value];

            str += currValue && needDiv ? currValue + divider : currValue ?? '';
        });
    } else {
        str = i18n.t('labels.no_data');
    }

    return str;
};

export default buildSubmissionRow;
