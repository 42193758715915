import React from 'react';
import { PublicLayout } from '../../../components/layout/layout';
import { Card, Col, List, Row } from '@pankod/refine-antd';
import { Link } from 'react-router-dom';
import { TranslatedBreadcrumbs } from '../../../components/translated-breadcrumbs';
import { tabulate } from '../../../components/specification/utils/tabulate';
import { cards } from '../../../components/specification/data/cards';
import styles from './list.module.scss';
import { useTranslate } from '@pankod/refine-core';

const SpecList = () => {
    const t = useTranslate();

    return (
        <PublicLayout>
            <List
                title={t('specs.specs')}
                pageHeaderProps={{ breadcrumb: <TranslatedBreadcrumbs /> }}
            >
                <div className={styles.cardList}>
                    <Row gutter={[16, 16]}>
                        {cards?.map(card => {
                            return (
                                <Col xs={24} md={12} key={card.id}>
                                    <Link to={card.href}>
                                        <Card
                                            type="inner"
                                            title={t(`${card.title}`)}
                                            bordered={true}
                                            className={styles.card}
                                        >
                                            {tabulate(card?.body, t)}
                                        </Card>
                                    </Link>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </List>
        </PublicLayout>
    );
};

export default SpecList;
