export function buildFormData(
    values: Record<string, any>,
    fileListKey = 'files'
): FormData {
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
        if (key === fileListKey) {
            return;
        }

        if (Array.isArray(value)) {
            // Якщо це масив
            value.forEach((item: any) => {
                formData.append(
                    `${key}[]`,
                    typeof item === 'boolean'
                        ? item
                            ? '1'
                            : '0'
                        : String(item)
                );
            });
        } else {
            formData.append(
                key,
                typeof value === 'boolean' ? (value ? '1' : '0') : String(value)
            );
        }
    });

    return formData;
}
