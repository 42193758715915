import { FC } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Empty, Table } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { validationMessage } from '../../../../../utils/validate';

interface StructureProps {
    data: any[];
    group: string;
    [key: string]: any;
}

interface Column {
    dossier_code: string;
    dossier_text: string;
    ectd_path: string;
    file_name: string;
    operation: string;
    title: string;
    upload_code: number;
    upload_text: string;
    uploaded_at: string;
    onFilter: () => void;
}

type Filter = {
    value: string | boolean | number;
    record: any;
};

export const Structure: FC<StructureProps> = ({ data, group, ...props }) => {
    const t = useTranslate();

    const onChangeRowClassName = ({ upload_code }: Column) => {
        return upload_code !== 200 && !!upload_code ? 'verror' : '';
    };

    const columns: ColumnsType<Column> = [
        {
            title: t(`${group}.operation`),
            dataIndex: 'operation',
            filters: [
                {
                    text: t(`${group}.new`),
                    value: 'new',
                },
                {
                    text: t(`${group}.append`),
                    value: 'append',
                },
                {
                    text: t(`${group}.replace`),
                    value: 'replace',
                },
                {
                    text: t(`${group}.delete`),
                    value: 'delete',
                },
            ],
            onFilter: (value: Filter['value'], record: Filter['record']) =>
                record.operation.indexOf(t(`${group}.${value}`)) === 0,
            filterMultiple: false,
        },
        {
            title: t(`${group}.title`),
            dataIndex: 'title',
        },
        {
            title: t(`${group}.file_name`),
            dataIndex: 'file_name',
        },
        {
            title: t(`${group}.ectd_path`),
            dataIndex: 'ectd_path',
        },
        {
            title: t(`${group}.state_of_structure`),
            dataIndex: '',
            render: (_, record: Column) =>
                validationMessage(+record?.dossier_code),
            width: 120,
        },
        {
            title: t(`${group}.uploaded_at`),
            dataIndex: 'uploaded_at',
        },
        {
            title: t(`${group}.file_state`),
            dataIndex: '',
            render: (_, record: Column) =>
                validationMessage(record?.upload_code),
            width: 120,
        },
    ];

    if (data?.length === 0 || data === undefined || data === null) {
        return <Empty description={t('applications.titles.no_data')} />;
    }

    return (
        <Table
            columns={columns}
            dataSource={data?.map(({ operation, ...rest }) => ({
                operation: t(`applications.titles.${operation}`),
                ...rest,
            }))}
            rowClassName={onChangeRowClassName}
            rowKey={row => {
                let r = (Math.random() + 1).toString(36).substring(7);
                return row.title + r;
            }}
            {...props}
        />
    );
};
