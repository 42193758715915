import styles from './FileUploader.module.scss';
import { Button, Progress, Upload, UploadProps } from '@pankod/refine-antd';
import { UploadOutlined } from '@ant-design/icons';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTusStore } from 'use-tus';
import config from '../../config/path';
import { useTranslate } from '@pankod/refine-core';
import { v4 as uuidv4 } from 'uuid';
import { getAuthKey } from '../../utils/auth';
import { toUpCase } from '../../utils/misc';

const cacheKey = uuidv4();

export type Success = string | null;

interface FileUploaderProps {
    onSuccess: (id: Success) => void;
    onRemove?: () => void;
    accept?: string;
    disabled?: boolean;
    message?: any;
}

export const FileUploader: FC<FileUploaderProps> = ({
    onSuccess,
    onRemove,
    accept,
    disabled,
    ...props
}) => {
    const t = useTranslate();
    const [progress, setProgress] = useState(0);
    const { setUpload, upload, isSuccess, isAborted, remove } = useTusStore(
        cacheKey,
        {
            autoAbort: true,
            uploadOptions: {
                uploadUrl: null,
            },
        }
    );
    const [fileList, setFileList] = useState<any[] | []>([]);
    const token = getAuthKey();

    useEffect(() => {
        if (isAborted) {
            remove();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAborted]);

    useEffect(() => {
        if (uploadedUrl) {
            let fileUuid = uploadedUrl.split('/').slice(-1)?.[0];

            if (fileUuid) {
                onSuccess(fileUuid);
            }
        } else {
            onSuccess(null);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const uploadedUrl = useMemo(
        () => isSuccess && upload?.url,
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [upload, isSuccess, isAborted]
    );

    const handleSetUpload = useCallback(
        async event => {
            const fileLastItem = [...event.fileList].pop();
            const file = fileLastItem?.originFileObj;

            setFileList([
                {
                    ...fileLastItem,
                },
            ]);

            if (!file) {
                return;
            }

            setUpload(file, {
                endpoint: `${config.API}/api/file-upload`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                //25mb in bytes
                chunkSize: 25 * 1024 * 1024,
                metadata: file
                    ? {
                          filename: `${cacheKey}_${file.name}`,
                          filetype: file.type,
                      }
                    : undefined,
                onProgress: (bytesSent, bytesTotal) => {
                    setProgress(
                        Number(((bytesSent / bytesTotal) * 100).toFixed(2))
                    );
                },
                removeFingerprintOnSuccess: true,
            });
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [setUpload]
    );

    const handleStart = useCallback(async () => {
        if (!upload) {
            return;
        }

        const previousUploads = await upload.findPreviousUploads();

        if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        upload.start();
    }, [upload]);

    const handleFileReset = () => {
        remove();
        setFileList([]);
        setProgress(0);
    };

    const uploadProps: UploadProps = {
        onChange: handleSetUpload,
        beforeUpload: () => false,
        multiple: false,
        className: styles.uploadButton,
        fileList,
        onRemove: () => {
            onRemove!();
            handleFileReset();
        },
        accept,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.line}>
                <Upload {...uploadProps}>
                    <Button htmlType="button" disabled={disabled}>
                        {t('select_a_file')}
                    </Button>
                </Upload>
                <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={handleStart}
                    htmlType="button"
                    disabled={disabled}
                >
                    {t('upload')}
                </Button>
                {props.message?.length && (
                    <span className={styles.errMessage}>
                        {/*// @ts-ignore*/}
                        {props.message.map((i: any, index: number) => {
                            let needPaymentExpertise =
                                i.expertise_status === 'не оплачено' ||
                                i.expertise_status === 'частково оплачено';
                            let needPaymentStateCollection =
                                i.state_collection_status === 'не оплачено' ||
                                i.state_collection_status ===
                                    'частково оплачено';

                            return (
                                <div className={styles.errItem} key={index}>
                                    {/*// @ts-ignore*/}
                                    {i.is_package &&
                                    (needPaymentExpertise ||
                                        needPaymentStateCollection) ? (
                                        <span key={`item-${index}`}>
                                            {'Пакетна заявка'}
                                            {/*// @ts-ignore*/} ({i.number} -{' '}
                                            {i.date}) {/*// @ts-ignore*/}
                                            {/*// @ts-ignore*/}
                                            {/* // @ts-ignore*/}
                                            {(needPaymentExpertise ||
                                                needPaymentStateCollection) &&
                                                toUpCase('не оплачено')}
                                            {/* // @ts-ignore*/}
                                            {i.not_expired_status !==
                                                'не прострочено' &&
                                                i.not_expired_status !== null &&
                                                toUpCase(i.not_expired_status)}
                                        </span>
                                    ) : (
                                        <Fragment key={`item-${index}`}>
                                            <span className={styles.errBlock}>
                                                {/*// @ts-ignore*/}
                                                {(needPaymentExpertise ||
                                                    needPaymentStateCollection) &&
                                                    toUpCase('не оплачено')}
                                                {/*// @ts-ignore*/}
                                            </span>

                                            <span className={styles.errBlock}>
                                                {i.not_expired_status !==
                                                    'не прострочено' &&
                                                    i.not_expired_status !==
                                                        null &&
                                                    toUpCase(
                                                        i.not_expired_status
                                                    )}
                                            </span>
                                        </Fragment>
                                    )}
                                </div>
                            );
                        })}
                    </span>
                )}
            </div>

            <div className={styles.line}>
                <Progress percent={progress} />
            </div>
        </div>
    );
};
