import { Collapse, Empty, Tabs } from '@pankod/refine-antd';
import React, { FC, useState } from 'react';
import { useTranslate } from '@pankod/refine-core';
import { Validation } from './validation';
import { Structure as SubmissionStructure } from './structure';
import { Title } from './title';

type Status = {
    id: number;
    code: string;
    name: string;
};

interface SubmissionsProps {
    submissions: Array<Submission>;
    status: Status;
    [key: string]: any;
}

interface ECTDValidation {
    status?: string;
    code: string;
    category: string;
    comment: string;
    criteria: string;
    details?: string | null;
}

interface Structure {
    title: string;
    file_name: string;
    ectd_path: string;
    operation: string;
    uploaded_at: string | null;
    upload_code: number | null;
    upload_text: string | null;
}

interface File {
    created_at: string;
    extension: string;
    name: string;
    size: string;
}

type Submission = {
    created_at: string;
    sequence: string;
    ectd_validation: ECTDValidation[];
    structure: Structure[];
    status: Status;
    file?: File;
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

export const Submissions: FC<SubmissionsProps> = ({
    submissions,
    status,
    ...props
}) => {
    const t = useTranslate();
    const [actSubKey, setActSubKey] = useState<any[] | number>([]);

    const onChangeAccordionItem = (key: any) => {
        let keys: any[] | number = [];

        if (key.length > 0) {
            keys = key.pop();
        } else {
            keys = keys.push(...key);
        }

        setActSubKey(keys);
    };

    if (
        submissions?.length === 0 ||
        submissions === undefined ||
        submissions === null
    ) {
        return <Empty description={t('applications.titles.no_data')} />;
    }

    return (
        <Collapse onChange={onChangeAccordionItem} activeKey={actSubKey}>
            {submissions?.map((submission: Submission, key: number) => {
                const {
                    created_at,
                    file,
                    sequence,
                    ectd_validation,
                    structure,
                    status: submissionStatus,
                } = submission;

                return (
                    <Panel
                        key={++key}
                        header={
                            <Title
                                createdAt={created_at}
                                sequence={sequence}
                                status={submissionStatus ?? status}
                                fileName={file?.name}
                            />
                        }
                    >
                        <Tabs defaultActiveKey="validation" onChange={() => {}}>
                            <TabPane
                                tab={t('applications.titles.validation')}
                                key="validation"
                            >
                                <Validation
                                    data={ectd_validation}
                                    group="applications.fields"
                                    scroll={{
                                        x: 500,
                                    }}
                                />
                            </TabPane>
                            <TabPane
                                tab={t('applications.titles.structure')}
                                key="structure"
                            >
                                <SubmissionStructure
                                    data={structure}
                                    group="applications.fields"
                                    scroll={{
                                        x: 500,
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </Panel>
                );
            })}
        </Collapse>
    );
};
