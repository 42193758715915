import React, { FC } from 'react';
import { useTranslate } from '@pankod/refine-core';
import { Table } from '@pankod/refine-antd';
import styles from './DataTable.module.scss';

interface IDataTableProps {
    data: any[];
    group?: string;
    [x: string]: any;
}

export const DataTable: FC<IDataTableProps> = ({
    data,
    group = 'applications.fields',
    ...props
}) => {
    const t = useTranslate();
    const yesno = (flag: boolean) => (flag ? 'yes' : 'no');

    const renderCell = (value: any) => {
        return Array.isArray(value) ? (
            <div>
                <pre className={styles.code}>
                    {JSON.stringify(value, null, 2)}
                </pre>
            </div>
        ) : (
            value
        );
    };

    const iteratedColumns: any[] = [];

    for (let k in data[0]) {
        iteratedColumns.push({
            title: t(`${group}.${k}`),
            dataIndex: k,
            render: (value: any) => {
                let subs = yesno(value);

                return typeof value === 'boolean'
                    ? t(`labels.${subs}`)
                    : renderCell(value);
            },
            className: 'align-top',
        });
    }

    return (
        <Table
            className="components-table-demo-nested"
            columns={iteratedColumns}
            dataSource={data}
            scroll={{
                x: 500,
            }}
            {...props}
        />
    );
};
