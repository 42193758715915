import { FC } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
    CrudFilters,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    EditButton,
    TagField,
    Col,
    Row,
    Card,
    CreateButton,
} from '@pankod/refine-antd';
import { IRecordFilterVariables, IApplication } from 'interfaces';

import { Filter } from '../../../components/applications/filter';
import { containsExpert } from '../../../utils/roles';
import styles from './List.module.scss';

export const ApplicationList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    interface IColumn {
        id: string;
        guid: string;
        name: string;
        registration_certificate_number: string;
        registration_certificate_date: string;
        status_pharmasol: {
            name: string;
        };
        type: {
            id: number;
            name: string;
            code: string;
        };
        status: {
            id: number;
            name: string;
            code: string;
        };
        created_at: string;
        updated_at: string;
        change_reason: any;
        change_description: any;
        applicant_name: any;
        representative_name: any;
        changes: any;
        expertises: any;
        state_history: any;
        medication: any;
        pharmasol_id: any;
        print_history: any;
    }

    const { tableProps, searchFormProps } = useTable<
        IApplication,
        HttpError,
        IRecordFilterVariables
    >({
        onSearch: params => {
            const filters: CrudFilters = [];
            const { status_id, type_id, query } = params;

            filters.push(
                {
                    field: 'query',
                    operator: 'eq',
                    value: query,
                },
                {
                    field: 'status_id',
                    operator: 'eq',
                    value: status_id,
                },
                {
                    field: 'type_id',
                    operator: 'eq',
                    value: type_id,
                }
            );

            return filters;
        },
    });

    const stringOutput = (
        item: any,
        keys: any[],
        divider: string = ' '
    ): string => {
        let str: string = '';

        if (keys.length) {
            keys.forEach((value, index) => {
                let needDiv = true;

                if (keys[keys.length - 1] === keys[index]) {
                    needDiv = false;
                }

                str += item[value] + (needDiv ? divider : '');
            });
        } else {
            str = t('labels.no_data');
        }

        return str;
    };

    const isAdmin = containsExpert();

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title={t('applications.titles.filters')}>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List
                    pageHeaderProps={{
                        extra: [
                            isAdmin ? (
                                <CreateButton key="create">
                                    {t(
                                        'applications.buttons.create_an_application_with_ectd'
                                    )}
                                </CreateButton>
                            ) : null,
                            /*<CreateButton
                                key="append"
                                onClick={(): void => push('/applications/add')}
                            >
                                {t(
                                    'applications.buttons.add_ectd_to_the_application'
                                )}
                            </CreateButton>,*/
                        ],
                    }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        pagination={{ ...tableProps.pagination }}
                        scroll={{ x: 500 }}
                        bordered
                    >
                        <Table.Column
                            dataIndex="name"
                            key="name"
                            title={t('applications.fields.trade_name')}
                            render={(value, row: IColumn) => (
                                <>
                                    <TextField value={value} />
                                    <br />
                                    <span className={styles.applicationGuid}>
                                        <TextField value={row.guid} />
                                    </span>
                                </>
                            )}
                            sorter
                            width={300}
                        />
                        <Table.Column
                            dataIndex="registration_certificate_number"
                            key="registration_certificate_number"
                            title={t('applications.fields.moz_data')}
                            render={(value, row: any) => {
                                const val = value
                                    ? stringOutput(
                                          row,
                                          [
                                              'registration_certificate_number',
                                              'registration_certificate_date',
                                          ],
                                          ' від '
                                      )
                                    : '';

                                return val && <TextField value={val} />;
                            }}
                            sorter
                            width={300}
                        />
                        <Table.Column
                            dataIndex={['status_pharmasol', 'name']}
                            key="status_pharmasol"
                            title={t('applications.fields.application_status')}
                            render={value => <TagField value={value} />}
                            sorter
                            width={200}
                        />
                        <Table.Column
                            dataIndex={['status', 'name']}
                            key="status_code"
                            title={t('applications.fields.ectd_dossier_status')}
                            render={value => <TagField value={value} />}
                            sorter
                        />
                        <Table.Column
                            dataIndex={['type', 'name']}
                            key="type_id"
                            title={t('applications.fields.procedure')}
                            sorter
                        />
                        <Table.Column
                            dataIndex="created_at"
                            key="created_at"
                            title={t('applications.fields.created_at')}
                            sorter
                        />
                        <Table.Column
                            title={t('table.actions')}
                            dataIndex="actions"
                            render={(_, record: any) => {
                                let itemId = record.id;

                                return (
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={itemId}
                                    />
                                );
                            }}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
