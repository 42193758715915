import React, { FC, useCallback, useState } from 'react';
import {
    IResourceComponentsProps,
    useNavigation,
    useTranslate,
} from '@pankod/refine-core';
import dayjs from 'dayjs';
import {
    Checkbox,
    Col,
    Create,
    Form,
    Input,
    Row,
    SaveButton,
    Select,
    Space,
    useForm,
    DatePicker,
    Spin,
} from '@pankod/refine-antd';

import { TranslatedBreadcrumbs } from '../../../components/translated-breadcrumbs';
import { removeObject, storeObject } from '../../../utils/accessory';
import { UploadFile } from 'antd/lib/upload/interface';
import { message } from 'antd';
import { api } from '../../../utils/api';
import MultiFileUploader from '../../../components/multi-file-uploader';
import { useNotification } from '../../../hooks/useNotification';
import { useDictionarySelectProps } from '../../../hooks/preliminary-application/useDictionarySelectProps';
import {
    FormFields,
    IFormValues,
} from '../../../interfaces/preliminary-application';
import { buildFormData } from '../../../utils/buildFormData';
import { errorManipulation } from '../../../utils/error-manipulation';
import { handleFormError } from '../../../utils/error-handlers';
import hydrateVErrors from '../../../utils/validate';
import { isValidValue } from '../../../utils/obj';
import { pickBy } from 'lodash';
import { sleep } from '../../../utils/sleep';
import { validateAndAppendFiles } from '../../../utils/validateAndAppendFiles';

const STORE_KEY: string = 'create';
const LOADER_DELAY_IN_MS: number = 2000;

export const PreliminaryApplicationCreate: FC<
    IResourceComponentsProps
> = () => {
    const t = useTranslate();
    const { replace } = useNavigation();
    const { msgSuccess, msgError } = useNotification();
    const { applicantProps, procedureProps, innProps, manufacturerProps } =
        useDictionarySelectProps();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);
    const [disSubmit, setDisSubmit] = useState<boolean>(false);

    const onAfterAdd = (appId: string): void => {
        if (appId) {
            removeObject(STORE_KEY);
            form.resetFields();

            setTimeout(
                () =>
                    (window.location.href = `/preliminary-applications/edit/${appId}`),
                1000
            );
        }
    };

    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }, variables, context) => {},
        onMutationError: error => {
            storeObject(STORE_KEY, error?.config?.data);

            const validationErrors = hydrateVErrors(error);

            // eslint-disable-next-line array-callback-return
            let errFields = Object.keys(validationErrors).map((key: string) => {
                let error = validationErrors?.[key];
                let messages = errorManipulation(error);

                return {
                    name: key
                        .split('.')
                        .map(i => (Number.isInteger(+i) ? +i : i)),
                    errors: [messages],
                };
            });

            //@ts-ignore
            form.setFields(errFields);
        },
        redirect: false,
        resource: 'preliminary-request',
    });

    const onValuesChange = () => {
        const errorList = form.getFieldsError();

        Object.keys(errorList).forEach((field, index) => {
            let name = `${errorList?.[index].name}`
                .split(',')
                .map(i => (Number.isInteger(+i) ? +i : i));

            form.setFields([
                {
                    name,
                    errors: [],
                },
            ]);
        });
    };

    const saveButton = {
        ...saveButtonProps,
        disabled: disSubmit,
    };

    const handleFileChange = useCallback(
        (newFileList: UploadFile[]) => {
            setFileList(newFileList);
            // @ts-ignore
            formProps?.form.setFieldsValue({ file: newFileList });
        },
        [formProps?.form]
    );

    const resetStatesHandler = (fields?: FormFields, formInstance = form) => {
        setIsFormProcessing(false);
        setDisSubmit(false);
        setFileList([]);

        if (fields) {
            formInstance.resetFields(fields);
        } else {
            formInstance.resetFields();
        }
    };

    const handleFinish = async (values: IFormValues) => {
        try {
            setDisSubmit(true);
            setIsFormProcessing(true);

            const reg_date = values?.reg_date
                ? dayjs(values?.reg_date).format('DD.MM.YYYY')
                : null;
            const payload = { ...values, reg_date };
            const filteredValues = pickBy(payload, isValidValue);

            const formData = buildFormData(filteredValues, 'files');

            await validateAndAppendFiles({
                fileList,
                formData,
                form,
                resetStates: (fields, formInstance) =>
                    resetStatesHandler(fields, formInstance),
                message,
                sleep,
                LOADER_DELAY_IN_MS,
            });

            const {
                data: { data },
            } = await api.post(`/api/preliminary-request`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            resetStatesHandler();
            onAfterAdd(data?.uuid);
            msgSuccess(
                t(
                    'notifications.the_draft_has_been_successfully_saved_take_care_of_sending_the_application'
                )
            );
        } catch (e) {
            console.error(e);
            handleFormError({
                e,
                form,
                setIsFormProcessing,
                setDisSubmit,
                msgError,
                t,
            });
        }
    };

    const getValueFromEvent = (fileList: UploadFile[]): UploadFile[] => {
        return fileList.map(file => ({
            uid: file.uid,
            name: file.name,
            status: file.status,
            url: file.url,
            originFileObj: file.originFileObj,
        }));
    };

    const validateFiles = (_: any, value: UploadFile[]) => {
        if (!value || value.length === 0) {
            return Promise.reject(
                new Error(
                    t(
                        'preliminary-applications.message.please_upload_your_files'
                    )
                )
            );
        }

        const allowedTypes = ['application/pdf'];
        const maxSizeMB = 10;

        for (const file of value) {
            if (file.originFileObj) {
                const { type, size, name } = file.originFileObj;

                if (!allowedTypes.includes(type)) {
                    return Promise.reject(
                        new Error(
                            `${name} ${t(
                                'preliminary-applications.message.has_an_incorrect_file_type'
                            )}`
                        )
                    );
                }

                if (size / 1024 / 1024 >= maxSizeMB) {
                    return Promise.reject(
                        new Error(
                            `${name} ${t(
                                'preliminary-applications.message.exceeds_maxsize',
                                { maxSize: maxSizeMB }
                            )}`
                        )
                    );
                }
            }
        }

        return Promise.resolve();
    };

    return (
        <Create
            actionButtons={
                <Space>
                    <SaveButton {...saveButton} />
                </Space>
            }
            pageHeaderProps={{
                breadcrumb: <TranslatedBreadcrumbs />,
                onBack: () => replace('/preliminary-applications'),
            }}
        >
            <Spin spinning={isFormProcessing}>
                <Form
                    {...formProps}
                    initialValues={{
                        reg_date: dayjs(),
                        is_electronic_form: false,
                        is_mibp: false,
                        is_ectd: false,
                        medication_name: '',
                        release_form: '',
                        international_name_id: null,
                        certificate: '',
                        applicant_id: null,
                        procedure_id: null,
                        procedure_desc: '',
                        reject_reason: '',
                        notification_email: '',
                        notes: '',
                        application_notes: '',
                        files: [],
                    }}
                    layout="vertical"
                    onFinish={handleFinish}
                    onValuesChange={onValuesChange}
                    disabled={isFormProcessing}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.reg_date'
                                )}
                                name="reg_date"
                                initialValue={dayjs()}
                            >
                                <DatePicker
                                    format="DD.MM.YYYY"
                                    style={{ minWidth: 300 }}
                                    placeholder={t(
                                        'preliminary-applications.fields.select_date'
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={6}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.electronic_form'
                                )}
                                name="is_electronic_form"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={3}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.ectd'
                                )}
                                name="is_ectd"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={3}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.mibp'
                                )}
                                name="is_mibp"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.medication_name'
                                )}
                                name="medication_name"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.release_form'
                                )}
                                name="release_form"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.certificate'
                                )}
                                name="certificate"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.applicant'
                                )}
                                name="applicant_id"
                            >
                                <Select {...applicantProps} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.procedure'
                                )}
                                name="procedure_id"
                            >
                                <Select {...procedureProps} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t('preliminary-applications.fields.inn')}
                                name="international_name_id"
                            >
                                <Select {...innProps} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.notification_email'
                                )}
                                name="notification_email"
                            >
                                <Input type="email" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} style={{ display: 'none' }}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.manufacturers'
                                )}
                                name="manufacturer_id"
                            >
                                <Select
                                    mode="multiple"
                                    {...manufacturerProps}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.procedure_desc'
                                )}
                                name="procedure_desc"
                            >
                                <Input.TextArea size={'large'} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t(
                                    'preliminary-applications.fields.application_notes'
                                )}
                                name="application_notes"
                            >
                                <Input.TextArea size={'large'} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t('upload_files')}
                                name="files"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                rules={
                                    fileList?.length
                                        ? [{ validator: validateFiles }]
                                        : []
                                }
                            >
                                <MultiFileUploader
                                    fileList={fileList}
                                    onChange={handleFileChange}
                                    accept=".pdf"
                                    listType="text"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Create>
    );
};
