import {
    ApplicationCreate,
    ApplicationEdit,
    ApplicationList,
} from '../pages/applications';
import {
    PreliminaryApplicationCreate,
    PreliminaryApplicationEdit,
    PreliminaryApplicationList,
} from '../pages/preliminary-application';
import {
    ExperimentOutlined,
    FormOutlined,
    FundProjectionScreenOutlined,
} from '@ant-design/icons';
import { ActivityList } from '../pages/activity';
import { PharmasolList, PharmasolShow } from '../pages/pharmasol';
import SpecList from '../pages/specs/list/list';
import SpecShow from '../pages/specs/show/show';

const resource: any[] = [
    {
        name: 'applications',
        list: ApplicationList,
        create: ApplicationCreate,
        edit: ApplicationEdit,
        icon: <FormOutlined />,
    },
    {
        name: 'preliminary-applications',
        list: PreliminaryApplicationList,
        create: PreliminaryApplicationCreate,
        edit: PreliminaryApplicationEdit,
        icon: <FormOutlined />,
        options: {
            label: 'preliminary-applications.preliminary-applications',
        },
    },
    {
        name: 'applications-external',
        list: () => null,
        options: {
            label: 'applications_external_page.applications_external_page',
        },
    },
    {
        name: 'form',
        list: () => null,
        options: { label: 'form' },
    },
    {
        name: 'history',
        list: () => null,
        options: { label: 'history' },
    },
    {
        name: 'clinical-trial',
        list: () => null,
        options: { label: 'clinicaltrial_items' },
    },
    {
        name: 'menu-forms',
        list: () => null,
        options: { label: 'menuforms' },
    },
    {
        name: 'activity',
        list: ActivityList,
        options: { label: 'activity.activity' },
        icon: <FundProjectionScreenOutlined />,
    },
    {
        name: 'dictionaries',
        list: PharmasolList,
        show: PharmasolShow,
        options: { label: 'pharmasol.dictionaries' },
        icon: <ExperimentOutlined />,
    },
    {
        name: 'specs',
        list: SpecList,
        show: SpecShow,
    },
    // {
    //     name: 'applications/flow',
    //     options: {
    //         label: i18next.t('paper_applications'),
    //     },
    // },
];

export default resource;
