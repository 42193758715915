import { FC } from 'react';
import { Button, Empty, Table } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface DocumentHistoryProps {
    data: any[];
    group: string;
}

interface Column {
    id: number;
    name: string;
    code: string;
    created: string;
    url: string;
}

export const DocumentHistory: FC<DocumentHistoryProps> = ({ data, group }) => {
    const t = useTranslate();

    const handleOpenDocument = (record: Column) => {
        window.open(
            record.url,
            'documentWindow',
            'left=20,top=20,width=984,height=675,toolbar=1,resizable=0'
        );

        return false;
    };

    const columns: ColumnsType<Column> = [
        {
            title: t(`${group}.name`),
            dataIndex: 'name',
            width: '35%',
        },
        {
            title: t(`${group}.date`),
            dataIndex: 'created',
            width: '35%',
        },
        {
            title: t(`${group}.code`),
            dataIndex: 'code',
            width: '20%',
        },
        {
            title: t(`${group}.details`),
            dataIndex: 'details',
            render: (value: string, record: Column) => {
                return (
                    <Button
                        // eslint-disable-next-line no-script-url
                        href="javascript:void(0)"
                        onClick={() => handleOpenDocument(record)}
                        icon={<EyeOutlined />}
                    >
                        {t('applications.titles.view')}
                    </Button>
                );
            },
        },
    ];

    if (data?.length === 0 || data === undefined || data === null) {
        return <Empty description={t('applications.titles.no_data')} />;
    }

    return <Table columns={columns} dataSource={data} rowKey="name" />;
};
