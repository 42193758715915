import React, { FC } from 'react';
import styles from './Title.module.scss';
import { useTranslation } from 'react-i18next';

type Status = {
    id: number;
    code: string;
    name: string;
};

interface TitleProps {
    createdAt?: string;
    sequence?: string;
    status: Status;
    fileName: string | undefined;
}

export const Title: FC<TitleProps> = ({
    sequence,
    status,
    createdAt,
    fileName,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {sequence === '0000' && status?.code === 'validation_error'
                ? fileName
                : sequence}
            &nbsp;-&nbsp;
            <span className={styles.muted}>{createdAt}</span>
            &nbsp;
            {status?.name && (
                <span className={styles.status}>
                    ({t(`applications.fields.${status?.code}`)})
                </span>
            )}
        </>
    );
};
