import React, { FC } from 'react';
import { PlusIcon } from '../plus-icon';
import { Upload } from '@pankod/refine-antd';

import styles from './UploadDragger.module.scss';

interface UploadDraggerProps {
    title: string;
    [key: string]: any;
}

export const UploadDragger: FC<UploadDraggerProps> = ({ title, ...props }) => {
    return (
        <Upload.Dragger beforeUpload={() => false} multiple={false} {...props}>
            <span className={styles.title}>
                <PlusIcon className={styles.circleIcon} />
                {title}
            </span>
        </Upload.Dragger>
    );
};
