import React, { useEffect, useMemo, useState } from 'react';
import { Refine, ResourceProps } from '@pankod/refine-core';
import { ErrorComponent } from '@pankod/refine-antd';
import './styles/antd.less';
import {
    Title,
    Header,
    Sider,
    Footer,
    PrivateLayout,
    OffLayoutArea,
} from 'components/layout';
import { useTranslation } from 'react-i18next';
import { Login } from 'pages/login';
import { api } from './utils/api';
import { TusClientProvider } from 'use-tus';
import { VisionProvider } from './context/vision';
import { BetterHelmet } from '@limeyfy/react-seo';
import { usePageName } from './hooks/usePageName';
import { useLocation } from 'react-use';
import { ConfigProvider } from 'antd';
import { dataProvider } from 'provider/dataProvider';
import { authProvider } from './authProvider';
import { notificationProvider } from './provider/notificationProvider';
import { NotificationContextProvider } from './context/NotificationContext';
import roleAccess from './config/roleAccess';
import { Navigate } from 'react-router-dom';

import resource from './config/resource';
import route from './config/route';
import uk_UA from 'antd/es/locale/uk_UA';
import en_US from 'antd/es/locale/en_US';

export interface IUser {
    id: number;
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
    full_name: string;
    email: string | null;
    roles: string[];
}

function App() {
    const { t, i18n } = useTranslation();
    const [newResources, setNewResources] = useState<
        ResourceProps[] | undefined
    >([]);

    const combinedRoutes = [
        ...route?.routes,
        { path: '/', element: <Navigate to="/login" replace /> },
    ];

    const customRouterProvider = useMemo(
        () => ({
            ...route,
            routes: combinedRoutes,
        }),
        [combinedRoutes]
    );

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const { pathname } = useLocation();
    const { pageName } = usePageName(pathname);
    const title = `${t('labels.unified_office_of_the_applicant')}: ${pageName}`;
    const locale = i18nProvider.getLocale();

    useEffect(() => {
        const user: IUser | null = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user') as string)
            : null;
        const userRole = user?.roles.at(-1);

        const mapOnResources = () =>
            resource?.map(resourceItem => {
                if (resourceItem.options && resourceItem.options.label) {
                    let label = resourceItem.options.label;
                    return { ...resourceItem, options: { label: t(label) } };
                }

                return resourceItem;
            });

        const filterByRole = (items: any[]) => {
            // @ts-ignore
            const allowedResources = roleAccess[userRole] || [];
            return items.filter(item => allowedResources.includes(item?.name));
        };

        let items = mapOnResources();
        if (items) {
            let filtered = user ? filterByRole(items) : items;
            setNewResources(filtered);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource, t, pathname]);

    return (
        <>
            <TusClientProvider>
                <BetterHelmet>
                    <title>{title.trim()}</title>
                    <meta name="description" content={pageName} />
                </BetterHelmet>

                <NotificationContextProvider>
                    <ConfigProvider locale={locale === 'uk' ? uk_UA : en_US}>
                        <VisionProvider>
                            <Refine
                                ReadyPage={() => null}
                                notificationProvider={notificationProvider}
                                catchAll={<ErrorComponent />}
                                routerProvider={customRouterProvider}
                                dataProvider={dataProvider(api)}
                                authProvider={authProvider}
                                LoginPage={Login}
                                resources={newResources}
                                Title={Title}
                                Header={Header}
                                Sider={Sider}
                                Footer={Footer}
                                Layout={PrivateLayout}
                                OffLayoutArea={OffLayoutArea}
                                i18nProvider={i18nProvider}
                            />
                        </VisionProvider>
                    </ConfigProvider>
                </NotificationContextProvider>
            </TusClientProvider>
        </>
    );
}

export default App;
