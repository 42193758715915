import React, { FC, useEffect, useState } from 'react';
import { Button, Tooltip } from '@pankod/refine-antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './VisionMode.module.scss';
import { useMediaQuery } from 'react-responsive';
import {
    HighlightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { MONO_KEY, ZOOM_KEY } from '../../constants/vision';
import { Link } from 'react-router-dom';

interface VisionModeProps {
    onChange?: () => void;
    open?: boolean | undefined;
    showLabel?: boolean;
    dropdownPosition?: 'top' | 'bottom';
}

let zoomLevel: number = 1;

const updateZoom = function (zoom: number, set: boolean) {
    let body = document.querySelector('body') as HTMLElement;

    if (set) {
        zoomLevel = zoom;
    } else {
        zoomLevel += zoom;
    }

    zoomLevel = parseFloat(zoomLevel?.toFixed(1));

    if (body) {
        // @ts-ignore
        body.style.zoom = zoomLevel;
        // @ts-ignore
        body.style['-moz-transform'] = `scale(${zoomLevel})`;
    }

    return zoomLevel;
};

const VisionMode: FC<VisionModeProps> = props => {
    const { t } = useTranslation();
    const [displayReset, setDisplayReset] = useState(false);
    const [visuallyImpaired, setVisuallyImpared] = useState(false);
    const isMob = useMediaQuery({ query: '(max-width: 767px)' });

    if (typeof window === 'undefined') {
        return null;
    }

    let body = document.querySelector('body');
    let vZoom = localStorage.getItem(ZOOM_KEY);
    let vMono = localStorage.getItem(MONO_KEY);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        let visuallyImpared = !!vZoom || !!vMono;

        setVisuallyImpared(visuallyImpared);

        if (vMono) {
            body?.classList.add('vision');
            setDisplayReset(true);
        } else if (vZoom) {
            updateZoom(+vZoom, true);
            setDisplayReset(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (visuallyImpaired) {
            if (vMono) {
                body?.classList.add('vision');
            }
            if (vZoom) {
                updateZoom(+vZoom, true);
            }
            setDisplayReset(true);
        }
        //eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [visuallyImpaired]);

    const onMono = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let isMono = localStorage.getItem(MONO_KEY);
        if (isMono) {
            body?.classList.remove('vision');
            localStorage.removeItem(MONO_KEY);
            setVisuallyImpared(false);
        } else {
            body?.classList.add('vision');
            localStorage.setItem(MONO_KEY, String(true));
            setVisuallyImpared(true);
        }
    };

    const onZoomIn = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let curZoom = updateZoom(0.1, false);

        setDisplayReset(true);
        if (curZoom === 1) {
            localStorage.removeItem(ZOOM_KEY);
            setVisuallyImpared(false);
        } else {
            localStorage.setItem(ZOOM_KEY, String(curZoom));
            setVisuallyImpared(true);
        }
    };

    const onZoomOut = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let curZoom = updateZoom(-0.1, false);
        setDisplayReset(true);
        if (curZoom === 1) {
            localStorage.removeItem(ZOOM_KEY);
            setVisuallyImpared(false);
        } else {
            localStorage.setItem(ZOOM_KEY, String(curZoom));
            setVisuallyImpared(true);
        }
    };

    const onReset = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        localStorage.removeItem(ZOOM_KEY);
        localStorage.removeItem(MONO_KEY);
        // @ts-ignore
        body.style.zoom = 1;
        // @ts-ignore
        body.style['-moz-transform'] = 'scale(1)';
        updateZoom(1, true);

        if (body?.classList.contains('vision')) {
            body?.classList.remove('vision');
        }

        setDisplayReset(false);
        setVisuallyImpared(false);
    };

    const onCollapse = () => {
        if (props.onChange) {
            props.onChange();
        }
    };

    const Content = () => {
        return (
            <>
                <Button
                    htmlType="button"
                    onClick={onCollapse}
                    style={{
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        padding: '5px 8px',
                        lineHeight: 1,
                    }}
                    className={styles.trigger}
                >
                    {visuallyImpaired ? (
                        <EyeOutlined />
                    ) : (
                        <EyeInvisibleOutlined />
                    )}
                </Button>
                {isMob && props.showLabel ? (
                    <div className={styles.label} onClick={onCollapse}>
                        <EyeOutlined />
                        {t('buttons.for_visually_impaired')}
                    </div>
                ) : null}
                <div
                    className={cn(styles.dropdownMenu, {
                        [styles.dropdownMenuOpen]: props.open,
                        [styles.collapsedView]: isMob && props.open,
                        [styles.posTop]:
                            props.dropdownPosition === 'top' ||
                            !props.dropdownPosition,
                        [styles.posBottom]: props.dropdownPosition === 'bottom',
                    })}
                >
                    <Link to="" onClick={onMono} className={styles.menuItem}>
                        <HighlightOutlined style={{ marginRight: 8 }} />
                        {t('labels.monochrome')}
                    </Link>
                    <Link to="" onClick={onZoomIn} className={styles.menuItem}>
                        <ZoomInOutlined style={{ marginRight: 8 }} />
                        {t('labels.enlarge')}
                    </Link>
                    <Link to="" onClick={onZoomOut} className={styles.menuItem}>
                        <ZoomOutOutlined style={{ marginRight: 8 }} />
                        {t('labels.reduce')}
                    </Link>
                    {displayReset && (
                        <Link
                            to=""
                            onClick={onReset}
                            className={styles.menuItem}
                        >
                            {t('labels.reset_the_settings')}
                        </Link>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className={styles.visionMode}>
            {isMob ? (
                <Content />
            ) : (
                <Tooltip
                    title={t(
                        'labels.version_for_people_with_visual_impairments'
                    )}
                    placement={'left'}
                >
                    <span>
                        <Content />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default VisionMode;
