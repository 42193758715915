import { useSelect } from '@pankod/refine-antd'; // приклад, не знаю точно, звідки ти береш useSelect
import { ICategory } from 'interfaces';

export const useDictionarySelectProps = () => {
    const { selectProps: applicantProps } = useSelect<ICategory>({
        resource: 'dictionary/applicants',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: procedureProps } = useSelect<ICategory>({
        resource: 'dictionary/preliminary_request_procedure',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: statusProps } = useSelect<ICategory>({
        resource: 'dictionary/preliminary_request_state',
        optionLabel: 'name',
        optionValue: 'id',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: innProps } = useSelect<ICategory>({
        resource: 'dictionary/international-names',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const { selectProps: manufacturerProps } = useSelect<ICategory>({
        resource: 'dictionary/manufacturers',
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    return {
        applicantProps,
        innProps,
        manufacturerProps,
        procedureProps,
        statusProps,
    };
};
