import React, { FC, Fragment } from 'react';
import { Col, Form, Row, Spin } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import styles from './Medication.module.scss';

interface MedicationInfoProps {
    formProps: any;
    isLoading: boolean;
}

export const MedicationInfo: FC<MedicationInfoProps> = ({
    formProps,
    isLoading,
}) => {
    const { t } = useTranslation();

    const initValues = formProps.initialValues;
    const medication = initValues?.medication;

    const handleSubmit = (values: any) => {};

    const arr2String = (arr: string[] | undefined) =>
        Array.isArray(arr) ? arr?.join(', ') : null;

    return (
        <Spin spinning={isLoading}>
            <Form {...formProps} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.name_of_medicine')}
                            name="medicationName"
                        >
                            <div>{medication?.medicationName}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'applications.fields.pharmaceutical_form_id'
                            )}
                            name="pharmaceuticalFormId"
                        >
                            <div>{medication?.pharmaceuticalFormId}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t(
                                'applications.fields.pharmaceutical_form_name'
                            )}
                            name="pharmaceuticalFormName"
                        >
                            <div>{medication?.pharmaceuticalFormName}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.release_form')}
                            name="releaseForm"
                        >
                            <div>{medication?.releaseForm}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.manufacturers')}
                            name="manufacturers"
                        >
                            <div>
                                {arr2String(medication?.manufacturers) ?? '-'}
                            </div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.inn')}
                            name="internationalNameIds"
                        >
                            <div>
                                {arr2String(medication?.internationalNameIds) ??
                                    '-'}
                            </div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={16}>
                        <Form.Item
                            label={t('applications.fields.drug_formulations')}
                            name="compositions"
                        >
                            <div>
                                {medication?.compositions.map((item: any) => {
                                    return (
                                        <Fragment key={item.number}>
                                            <div>
                                                <span
                                                    className={styles.subTitle}
                                                >
                                                    {t(
                                                        'applications.fields.active_ingredients'
                                                    )}
                                                    {':'}
                                                </span>
                                                {item?.activeIngredients.map(
                                                    (i: any) => (
                                                        <span
                                                            key={i}
                                                            className={
                                                                styles.item
                                                            }
                                                        >
                                                            {i.name}
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                            <div>
                                                {item?.additionalIngredients
                                                    .length ? (
                                                    <>
                                                        <span
                                                            className={
                                                                styles.subTitle
                                                            }
                                                        >
                                                            {t(
                                                                'applications.fields.additional_ingredients'
                                                            )}
                                                            {':'}
                                                        </span>
                                                        {item
                                                            ?.additionalIngredients
                                                            ?.length &&
                                                            item?.additionalIngredients.map(
                                                                (i: string) => (
                                                                    <span
                                                                        key={i}
                                                                    >
                                                                        {i}
                                                                    </span>
                                                                )
                                                            )}
                                                    </>
                                                ) : null}
                                            </div>
                                            {item.description && (
                                                <div>
                                                    <span
                                                        className={
                                                            styles.subTitle
                                                        }
                                                    >
                                                        {t(
                                                            'applications.fields.description'
                                                        )}
                                                        {':'}
                                                    </span>
                                                    {item.description}
                                                </div>
                                            )}
                                            {item.number && (
                                                <div>
                                                    <span
                                                        className={
                                                            styles.subTitle
                                                        }
                                                    >
                                                        {t(
                                                            'applications.fields.composition_number'
                                                        )}
                                                        {':'}
                                                    </span>
                                                    {item.number}
                                                </div>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.atx')}
                            name="atxCode"
                        >
                            <div>{medication?.atxCode}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.industry_of_use')}
                            name="usage"
                        >
                            <div>{medication?.usage ?? '-'}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.special_notes')}
                            name="specialNotes"
                        >
                            <div>{medication?.specialNotes ?? '-'}</div>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('applications.fields.storage_period')}
                            name="storagePeriod"
                        >
                            <div>{medication?.storagePeriod ?? '-'}</div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};
