import { FC } from 'react';
import {
    Button,
    Col,
    Form,
    FormProps,
    Input,
    Row,
    Select,
} from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';

import styles from './Filter.module.scss';
import { useDictionarySelectProps } from '../../../hooks/preliminary-application/useDictionarySelectProps';

export const Filter: FC<{ formProps: FormProps }> = ({ formProps }) => {
    const t = useTranslate();
    const { statusProps } = useDictionarySelectProps();

    return (
        <Form layout="vertical" {...formProps} className={styles.filterWrapper}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('preliminary-applications.fields.reg_num')}
                        name="reg_num"
                    >
                        <Input
                            allowClear
                            placeholder={t(
                                'preliminary-applications.fields.reg_num'
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('preliminary-applications.fields.state')}
                        name="state_id"
                    >
                        <Select
                            {...statusProps}
                            allowClear
                            placeholder={t(
                                'preliminary-applications.fields.state'
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t('applications.buttons.filter')}
                </Button>
            </Form.Item>
        </Form>
    );
};
